function ErrorPage() {
    return (
        <>
            <main role="main">

                <h1 className="display-5 text-center">Error, la pagina no existe.</h1>

            </main>
        </>
    );
}

export default ErrorPage;