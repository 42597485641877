function DataDeletionInstructions() {
    return (
        <>
            <p>If You wish to remove any personal data collected by Us, You can contact Us:</p>
            <ul>
                <li>By email: elizabethneirar@gmail.com</li>
            </ul>
        </>
    );
}

export default DataDeletionInstructions;