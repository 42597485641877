import Footer from "./Footer";
import DetailsHeading from "./classDetails/DetailsHeading";

import '../image-gallery.css';

function RetreatBaliSept2024Schedule() {

    return (<>
            <main role="main" className="container">

                <DetailsHeading text='Retiro de Yoga y Bienestar'/>
                <h4 className="details-subheading">Ubud - Bali, Septiembre 2024</h4>
                <div className="container details text-justify">

                    <div className="row">
                        <h3 className="text-center text-justify">Programación</h3>

                        <div className="row">
                            <div className="col">
                                <p className="fw-bolder">Dia 1 - Miercoles</p>
                                <ul>
                                    <li>Ceremonia de Bienvenida 4 Pm <br/>(Check-in desde 2 pm)</li>
                                    <li>Cena de Integración</li>
                                </ul>
                                <p className="fw-bolder">Dia 2 - Jueves</p>
                                <ul>
                                    <li>Pranayama(EJercicios de Respiración)</li>
                                    <li> Práctica de Vinyasa Yoga Multinivel (Todos los niveles)</li>
                                    <li> Irémos más profundo
                                        en los diferentes tipos de Meditación enfocandonos en el poder de los
                                        mantras
                                    </li>
                                    <li>Tiempo libre
                                        para
                                        descansar,
                                        escribir, leer, disfrutar la piscina, o simplemente conectar con el resto de los
                                        integrantes del retiro
                                    </li>
                                    <li> Yin yoga para relajar cuerpo y mente</li>
                                    <li> Baño de sonidos con cuencos
                                        tibetanos
                                        y de
                                        cristal.
                                    </li>
                                </ul>
                                <p className="fw-bolder">Dia 3 - Viernes</p>
                                <ul>
                                    <li>Pranayama(EJercicios de Respiración)</li>
                                    <li> Ashtanga Yoga (Primera Serie, para Todos los Niveles)</li>
                                    <li> Meditación guiada</li>
                                    <li> Taller de Ayurveda</li>
                                    <li>Excursiones:</li>
                                    <li> Visita a los Arrozales</li>
                                    <li> Visita al Templo de Purificación de Agua</li>
                                    <li> integración/actividades sociales luego de la
                                        cena.
                                    </li>
                                </ul>
                                <p className="fw-bolder">Dia 4 - Sábado</p>
                                <ul>
                                    <li>Pranayama(EJercicios de Respiración)</li>
                                    <li>Bhakti Yoga (Yoga Devocional, Todos los Niveles)</li>
                                    <li>Baño de sonidos con cuencos tibetanos y de cristal</li>
                                    <li>Meditación con pintura y colores</li>
                                    <li>Tiempo libre para ti</li>
                                    <li>Clase de Vinyasa para Avanzar en tu Práctica.</li>
                                </ul>
                                <p className="fw-bolder">Dia 5 - Domingo</p>
                                <ul>
                                    <li>Pranayama(EJercicios de Respiración)</li>
                                    <li>Práctica de Vinyasa Yoga Multinivel (Todos los Niveles)</li>
                                    <li>Ritual de Manifestación</li>
                                    <li> Excursiones:</li>
                                    <li>Visita al Monkey Forest</li>
                                    <li>Visita a una hermosa Cascada Natural para soltar y fluir a través del
                                        agua
                                    </li>
                                    <li>integración/actividades sociales luego de la cena.</li>
                                </ul>
                                <p className="fw-bolder">Dia 6 - Lunes</p>
                                <ul>
                                    <li> Pranayama - (Ejercicios de Respiración)</li>
                                    <li> Práctica de Vinyasa Yoga Multinivel (Todos los Niveles)</li>
                                    <li>Baño de sonidos con cuencos tibetanos y de
                                        cristal
                                    </li>
                                    <li>Ceremonia de Cierre. <br/>(Check-out 12 pm)</li>
                                </ul>
                                <p>
                                    <i>Nota: Se pueden agregar más actividades y/o cambiar ligeramente en el orden y
                                        dia.</i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

            <br/>

            <Footer/>
        </>
    );
}

export default RetreatBaliSept2024Schedule;
